import { Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
// import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const EmailClipboard = ({ children }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText("vishnutejaap@gmail.com").then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };
  return (
    <Tooltip title={copied ? "Copied" : "Click to copy email"}>
      <Button
        sx={{
          textTransform: "lowercase",
          fontSize: "16px",
        }}
        onClick={handleCopyToClipboard} // Attach the onClick event
        // endIcon={<ContentCopyRoundedIcon />}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default EmailClipboard;
